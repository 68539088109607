import React from 'react';
import styled from 'styled-components/macro';
import { border, color, fontFamily, fontSize, space, width } from 'styled-system';
import { BorderProps, ColorProps, FontFamilyProps, FontSizeProps, SpaceProps, WidthProps } from 'styled-system';

export interface ITextFieldProps extends BorderProps, ColorProps, FontFamilyProps, FontSizeProps, SpaceProps, WidthProps {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
}

const TextField = styled.input<ITextFieldProps>`s
    ${border}
    ${color}
    ${fontFamily}
    ${fontSize}
    ${space}
    ${width}

    &:focus {
        outline: none;
    }

    ::placeholder {
        color: ${props => props.theme.colors['text-disabled']};
    }
`;

export default TextField;

TextField.defaultProps = {
    backgroundColor: 'background-light',
    border: 0,
    color: 'text',
    fontFamily: 'text',
    fontSize: 1,
    px: 2,
    py: 2,
    width: '100%'
};
