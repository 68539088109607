import React, { useState } from 'react';

import TextArea from 'style/inputs/TextArea';
import TextField from 'style/inputs/TextField';
import Box from 'style/layout/Box';
import Flex from 'style/layout/Flex';
import Heading from 'style/typography/Heading';
import Text from 'style/typography/Text';

interface IRequestDetails {
    numberOfRows?: number;
    onReady: (details: { token: string, query: string }) => void;
    queryType: string;
}

const RequestDetails: React.FC<IRequestDetails> = (props) => {
    const { numberOfRows, onReady, queryType } = props;

    const [token, setToken] = useState<string | null>(null);
    const [query, setQuery] = useState<string | null>(null);

    const queryError = validateQuery(query, queryType);

    const isValid = token && query && queryError === null;

    const onContinue = () => {
        if (token && query) {
            onReady({
                query: query.trim(),
                token: token.trim()
            });

            setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight);
            }, 200);
        }
    };

    return (
        <Box borderTop={1} pt={2}>
            <Heading fontSize={3} fontWeight={3}>API Request Details</Heading>

            <Flex mt={2} flexDirection="row" alignItems="center" justifyContent="flex-start">
                <Box width={100}>
                    <Text fontSize={1}>API Token:</Text>
                </Box>

                <Box flex={1}>
                    <TextField border={1} placeholder="eyJraWQi..." onChange={ee => setToken(ee.target.value)} />
                </Box>
            </Flex>

            <Flex mt={4} flexDirection="row" alignItems="center" justifyContent="flex-start">
                <Box width={100}>
                    <Text fontSize={1}>Query:</Text>
                </Box>

                <Box flex={1}>
                    <TextArea
                        border={1}
                        rows={15}
                        placeholder="query { nevdisPlateSearch_v2 { ..."
                        spellCheck={false}
                        onChange={ee => setQuery(ee.target.value)}
                    />
                </Box>
            </Flex>

            {
                queryError && (
                    <Box backgroundColor="negative" p={3}>
                        <Text fontSize={1} color="white">* {queryError}</Text>
                    </Box>
                )
            }

            {
                isValid && (
                    <Box mt={3} p={2} border={1} maxWidth={300} backgroundColor="positive" style={{ cursor: 'pointer' }} onClick={onContinue} textAlign="center">
                        <Text fontSize={1} color="white">({numberOfRows} rows) Start requesting data from NEVDIS</Text>
                    </Box>
                )
            }
        </Box>
    );
};

export default RequestDetails;

const validateQuery = (qq: string | null, queryType: string): string | null => {
    if (!qq) {
        return null;
    }

    if (queryType === 'plate') {
        if (qq.indexOf('nevdisPlateSearch_v2') === -1) {
            return `We only support 'Plate to Vin' queries that use 'nevdisPlateSearch_v2' in the query`;
        }

        if (qq.indexOf('$plate: String!') === -1) {
            return `The query is missing a '$plate' variable`;
        }

        if (qq.indexOf('$state: State!') === -1) {
            return `The query is missing a '$state' variable`;
        }
    }
    else if (queryType === 'vin') {
        if (qq.indexOf('nevdisVINSearch_v2') === -1) {
            return `VIN queries must use 'nevdisVINSearch_v2'`;
        }

        if (qq.indexOf('$vin: String!') === -1) {
            return `The query is missing a '$vin' variable`;
        }
    }

    if (qq.indexOf('preferCache: true') === -1) {
        return `This query does not use data from the cache! Make sure to add 'preferCache: true' to your query`;
    }

    return null;
};