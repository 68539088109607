import React, { useState } from 'react';

import Box from 'style/layout/Box';
import Stack from 'style/layout/Stack';
import Heading from 'style/typography/Heading';
import Text from 'style/typography/Text';

import Execute, { IResultProps } from './Execute/Execute';
import QueryType from './QueryType';
import RequestDetails from './RequestDetails';
import Results from './Results';
import UploadCSV from './UploadCSV';
import ValidateCSV from './ValidateCSV';

const NevdisBatchFiles: React.FC = (props) => {
    const [fileName, setFileName] = useState('');
    const [queryType, setQueryType] = useState<string | null>(null);
    const [rows, setRows] = useState<{}[] | null>(null);
    const [columns, setColumns] = useState<{ plateColumn: string, stateColumn: string } | { vinColumn: string } | null>(null);
    const [request, setRequest] = useState<{ token: string, query: string } | null>(null);
    const [response, setResponse] = useState<IResultProps[] | null>(null);

    return (
        <Box p={4}>
            <Box>
                <Heading fontSize={5}>NEVDIS Batch File Loader</Heading>
                <Text fontSize={2} color="text-light">This service will take a csv file with columns for rego and state and append additional data which is fetched via the blueflag api</Text>
            </Box>

            <Box backgroundColor="background-darker" p={2}>
                <Text opacity={1} fontSize={1} color="informative">* Be careful using this service.</Text>
                <Text fontSize={1} color="informative">* Each run will cost money.</Text>
                <Text fontSize={1} color="informative">* If you run the entire file twice, we will get charged twice</Text>
            </Box>

            <Stack space={5} mt={5}>
                <UploadCSV onChange={(ff, rr) => {
                    setRows(rr);
                    setFileName(ff);
                }} />

                {rows && <QueryType onComplete={cc => setQueryType(cc)} />}

                {rows && queryType && <ValidateCSV rows={rows} queryType={queryType} onValid={cc => setColumns(cc)} />}

                {columns && queryType && <RequestDetails queryType={queryType} numberOfRows={rows?.length} onReady={req => setRequest(req)} />}

                {columns && rows && request && <Execute columns={columns} rows={rows} request={request} onComplete={data => !response ? setResponse(data) : null} />}

                {response && <Results fileName={fileName} response={response} />}
            </Stack>
        </Box >
    );
};

export default NevdisBatchFiles;
