import React from 'react';

import Box from 'style/layout/Box';
import Inline from 'style/layout/Inline';
import Heading from 'style/typography/Heading';
import Text from 'style/typography/Text';

interface IQueryType {
    onComplete: (queryType: string) => void;
}

const QueryType: React.FC<IQueryType> = (props) => {
    const { onComplete } = props;
    const onFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onComplete(event.target.value);
    };

    return (
        <Box borderTop={1} pt={2}>
            <Heading fontSize={3} fontWeight={3}>Query Type</Heading>
            <Text fontSize={1} color="text-light">So we can validate your request properly. We need to know what type of query you would like to perform.</Text>

            <Box mt={3}>
                <form>
                    <Inline space={3}>
                        <Box>
                            <input type="radio" name="queryType" value="plate" onChange={onFormChange} />
                            <label htmlFor="plate">Plate</label>
                        </Box>
                        <Box>
                            <input type="radio" name="queryType" value="vin" onChange={onFormChange} />
                            <label htmlFor="vin">VIN</label>
                        </Box>
                        {/* <Box>
                            <input type="radio" name="queryType" value="chassis" />
                            <label htmlFor="chassis">Chassis</label>
                        </Box> */}
                    </Inline>
                </form>
            </Box>
        </Box>
    );
};

export default QueryType;
