import React from 'react';

import Box from 'style/layout/Box';
import Flex from 'style/layout/Flex';
import Heading from 'style/typography/Heading';

const Navigation: React.FC = (props) => {
    return (
        <Flex
            borderBottom={1}
            alignItems="center"
            justifyContent="center"
        >
            <Box width={200} p={2}>
                <Heading fontSize={3} color="primary">blueflag data</Heading>
            </Box>

            <Box flex={1}>
                {props.children}
            </Box>
        </Flex>
    );
};

export default Navigation;
