import styled from 'styled-components/macro';
import { flexbox } from 'styled-system';
import { FlexboxProps } from 'styled-system';

import Box from './Box';
import { IBoxProps } from './Box';

export interface IFlexBoxProps extends IBoxProps, FlexboxProps { }

const Flex = styled(Box) <IBoxProps>`
    ${flexbox}
`;

export default Flex;

Flex.defaultProps = {
    display: 'flex'
};
