import { color } from './colors';

const theme = {
    borders: [
        0,
        `1px solid ${color('gray-300')}`,
        `2px solid ${color('gray-300')}`,
        `4px solid ${color('gray-300')}`,
        `8px solid ${color('gray-300')}`,
        `16px solid ${color('gray-300')}`,
        `32px solid ${color('gray-300')}`
    ],
    breakpoints: ['415px', '769px', '1024px'],
    colors: {
        // Theme colour aliases
        // This is the naming convention to use
        // {name}-lighter
        // {name}-light
        // {name}
        // {name}-dark
        // {name}-darker

        primary: '#1772c1',
        'primary-contrast': color('white'),

        secondary: color('orange-500'),
        'secondary-contrast': color('white'),

        'background': color('gray-100'),
        'background-dark': color('gray-100'),
        'background-darker': color('gray-200'),
        'background-light': color('gray-75'),
        'background-lighter': color('gray-50'),

        'border': color('gray-300'),
        'border-dark': color('gray-400'),

        'text': color('gray-800'),
        'text-dark': color('gray-900'),
        'text-disabled': color('gray-500'),
        'text-light': color('gray-700'),

        informative: color('blue-500'),
        negative: color('red-500'),
        notice: color('orange-500'),
        positive: color('green-500')
    },
    fontSizes: [12, 14, 16, 20, 24, 36, 48, 80, 96],
    fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    fonts: {
        heading: 'Montserrat, sans-serif',
        text: 'Roboto, sans-serif'
    },
    heights: [16, 32, 64, 128, 256],
    lineHeights: {
        heading: 1.8,
        text: 1.4
    },
    maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
    radii: [0, 2, 4, 16, 9999, '100%'],
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    width: [16, 32, 64, 128, 256]
};

export default theme;
