import React, { useState } from 'react';

import Box from 'style/layout/Box';
import Inline from 'style/layout/Inline';
import Heading from 'style/typography/Heading';
import Text from 'style/typography/Text';

interface IValidateCSV {
    rows: {}[];
    queryType: string;
    onValid: (columns: { plateColumn: string, stateColumn: string } | { vinColumn: string }) => void;
}

const ValidateCSV: React.FC<IValidateCSV> = (props) => {
    const { rows, queryType, onValid } = props;

    const columns = Object.keys(rows[0]);
    const [plateColumn, setPlateColumn] = useState<string | null>(null);
    const [stateColumn, setStateColumn] = useState<string | null>(null);
    const [vinColumn, setVinColumn] = useState<string | null>(null);

    let validationErrors: string[] = [];

    if (queryType === 'plate') {
        if (plateColumn && plateColumn !== '') {
            const plateErrors = validatePlate(plateColumn, rows);
            validationErrors = validationErrors.concat(plateErrors);
        }

        if (stateColumn && stateColumn !== '') {
            const stateErrors = validateState(stateColumn, rows);
            validationErrors = validationErrors.concat(stateErrors);
        }

        if (plateColumn && stateColumn && plateColumn === stateColumn) {
            // tslint:disable-next-line: max-line-length
            validationErrors = [`If i've told ya once, i've told ya a million times. Plate and State must be in two seperate columns. Make sure you've selected the correct columns from the dropdowns above and double check the column names in the CSV.`];
        }
    }
    else if (queryType === 'vin') {
        if (vinColumn && vinColumn !== '') {
            const vinErrors = validateVIN(vinColumn, rows);
            validationErrors = validationErrors.concat(vinErrors);
        }
    }

    const isValid = (
        ((queryType === 'plate' && plateColumn && plateColumn !== '' && stateColumn && stateColumn !== '') || (queryType === 'vin' && vinColumn && vinColumn !== ''))
        && validationErrors.length === 0
    );

    const onComplete = () => {
        if (queryType === 'plate') {
            if (plateColumn && stateColumn) {
                onValid({
                    plateColumn,
                    stateColumn
                });

                setTimeout(() => {
                    window.scrollTo(0, document.body.scrollHeight);
                }, 200);
            }
        }
        else if (queryType === 'vin') {
            if (vinColumn) {
                onValid({
                    vinColumn
                });

                setTimeout(() => {
                    window.scrollTo(0, document.body.scrollHeight);
                }, 200);
            }
        }
    };

    const successMessages = [
        'Your file is looking good. Shall we continue?',
        `You look stressed, don't be, this file looks good!`,
        'Sweet! Their CSV looks good to me. Its good to go.'
    ];

    return (
        <Box borderTop={1} pt={2}>
            <Heading fontSize={3} fontWeight={3}>Validation</Heading>
            <Text fontSize={1} color="primary">We found <b>{rows.length}</b> rows</Text>

            <Box mt={4}>
                <Text fontSize={1} color="text-light">We need to know which columns contain the plate and state.</Text>

                {
                    queryType === 'plate' && (
                        <>
                            <Inline mt={4} space={2}>
                                <Box minWidth={130} textAlign="right">
                                    <Text fontSize={1}>Rego/Plate column:</Text>
                                </Box>

                                <select defaultValue="" onChange={(ee) => setPlateColumn(ee.target.value)} style={{ minWidth: 150 }}>
                                    <option disabled value=""> -- select an option -- </option>
                                    {columns.map((cc, ii) => <option key={ii} value={cc}>{cc}</option>)}
                                </select>
                            </Inline>

                            <Inline mt={2} space={2}>
                                <Box minWidth={130} textAlign="right">
                                    <Text fontSize={1}>State column:</Text>
                                </Box>

                                <select defaultValue="" onChange={(ee) => setStateColumn(ee.target.value)} style={{ minWidth: 150 }}>
                                    <option disabled value=""> -- select an option -- </option>
                                    {columns.map((cc, ii) => <option key={ii} value={cc}>{cc}</option>)}
                                </select>
                            </Inline>
                        </>
                    )
                }

                {
                    queryType === 'vin' && (
                        <>
                            <Inline mt={4} space={2}>
                                <Box minWidth={130} textAlign="right">
                                    <Text fontSize={1}>VIN column:</Text>
                                </Box>

                                <select defaultValue="" onChange={(ee) => setVinColumn(ee.target.value)} style={{ minWidth: 150 }}>
                                    <option disabled value=""> -- select an option -- </option>
                                    {columns.map((cc, ii) => <option key={ii} value={cc}>{cc}</option>)}
                                </select>
                            </Inline>
                        </>
                    )
                }
            </Box>

            {
                isValid
                    ? (
                        <Box py={3} mt={2}>
                            <Text fontSize={1} color="positive">{randomMessage(successMessages)}</Text>
                            <Box mt={3} p={2} border={1} maxWidth={80} backgroundColor="positive" style={{ cursor: 'pointer' }} onClick={onComplete}>
                                <Text fontSize={1} color="white">Continue</Text>
                            </Box>
                        </Box>
                    )
                    : validationErrors.length > 0 && (
                        <Box backgroundColor="negative" p={3} mt={2}>
                            {validationErrors.map((ve, ii) => <Text key={ii} fontSize={2} color="white">* {ve}</Text>)}
                        </Box>
                    )
            }
        </Box>
    );
};

export default ValidateCSV;

const validatePlate = (columnName: string, rows: {}[]): string[] => {
    // tslint:disable-next-line: prefer-const
    let errors: string[] = [];

    rows.find((rr: { [key: string]: string }, ii) => {
        const val = (rr[columnName] + '').toUpperCase().trim();

        const plateMessages = [
            `I quickly had a look at the '${columnName}' column and the plate doesn't look right. Can you please double check for me? It's on line ${ii + 2}`,
            `Line ${ii + 2}, column '${columnName}' looks fishy to me. Can you please take a look and make sure its correct.`
        ];

        if (val.length === 0) {
            errors.push(randomMessage(plateMessages));

            return true;
        }

        return false;
    });

    return errors;
};

const validateState = (columnName: string, rows: {}[]): string[] => {
    // tslint:disable-next-line: prefer-const
    let errors: string[] = [];

    rows.find((rr: { [key: string]: string }, ii) => {
        const val = (rr[columnName] + '').toUpperCase().trim();

        if (val.length === 0) {
            errors.push(`Hmmm, I can't seem to find the value for state on line ${ii + 2}. Could you please fix that and upload the file again?`);
            return true;
        }
        else if (
            val !== 'VIC' &&
            val !== 'NSW' &&
            val !== 'QLD' &&
            val !== 'WA' &&
            val !== 'SA' &&
            val !== 'TAS' &&
            val !== 'NT' &&
            val !== 'ACT'
        ) {
            errors.push(`I've never heard of the state of '${val}' and I can't see our government invading new territories so I suspect this value may not be correct. Could you please double check line ${ii + 2} in the CSV.`);
            return true;
        }

        return false;
    });

    return errors;
};

const randomMessage = (arr: string[]): string => {
    const randIndex = Math.floor(Math.random() * arr.length + 1);

    return arr[randIndex - 1];
};

const validateVIN = (columnName: string, rows: {}[]): string[] => {
    // tslint:disable-next-line: prefer-const
    let errors: string[] = [];

    rows.find((rr: { [key: string]: string }, ii) => {
        const val = (rr[columnName] + '').toUpperCase().trim();

        const plateMessages = [
            `After taking a look at the '${columnName}' column, I think the VIN on line ${ii + 2} isn't correct. Shouldn't it always be 17 characters?`,
            `Line ${ii + 2}, column '${columnName}' looks fishy to me. Can you please take a look and make sure its correct. I'm expecting it to be 17 characters.`
        ];

        if (val.length < 17 || val.length > 17) {
            errors.push(randomMessage(plateMessages));

            return true;
        }

        return false;
    });

    return errors;
};
