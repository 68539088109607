import React, { ReactNodeArray } from 'react';
import Box from './Box';
import Flex, { IFlexBoxProps } from './Flex';

interface IInlineProps {
    space: number | number[];
    children: ReactNodeArray;
}

const Inline: React.FC<IInlineProps & IFlexBoxProps> = (props) => {
    const { space, children, ...rest } = props;

    const flexProps: any = rest;

    return (
        <Flex {...flexProps} flexDirection="row">
            {
                props.children.map((cc, ii) => (
                    <Box key={ii} ml={ii === 0 ? undefined : space}>
                        {cc}
                    </Box>
                ))
            }
        </Flex>
    );
};

export default Inline;

Inline.defaultProps = {
    alignItems: 'center',
    justifyContent: 'flex-start'
};