import './reset.css';
import './App.css';

import React from 'react';
import { ThemeProvider } from 'styled-components';

import theme from 'style/themes/blueflag-light'
import Box from 'style/layout/Box';
import Nav from 'components/Nav';

import NevdisBatchFiles from 'pages/NevdisBatchFiles';

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Box>
                <Nav />
                <NevdisBatchFiles />
            </Box>
        </ThemeProvider>
    );
}

export default App;
