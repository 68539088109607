import React from 'react';

import Box from 'style/layout/Box';
import Flex from 'style/layout/Flex';
import Inline from 'style/layout/Inline';
import Text from 'style/typography/Text';

const Progress: React.FC<{ success: number, errors: number, total: number }> = (props) => {
    const { success, errors, total } = props;

    const completed = success + errors;
    const inProgress = total - completed;

    if (total === 0) {
        return null;
    }

    const successWidth = (success / total) * 100;
    const errorsWidth = (errors / total) * 100;
    const inProgressWidth = (inProgress / total) * 100;

    const barProps = {
        height: 20
    };

    return (
        <Box>
            <Flex width="100%" alignItems="center" justifyContent="flex-start">
                <Box flex={1}>
                    <Flex width="100%">
                        <Box {...barProps} width={successWidth + '%'} backgroundColor="positive"></Box>
                        <Box {...barProps} width={errorsWidth + '%'} backgroundColor="negative"></Box>
                        <Box {...barProps} width={inProgressWidth + '%'} backgroundColor="background-darker"></Box>
                    </Flex>
                </Box>
                <Box minWidth={150} px={3}>
                    <Text fontSize={1}>
                        {((completed / total) * 100).toFixed(1)}% ({completed} / {total})
                </Text>
                </Box>
            </Flex>

            <Inline space={3}>
                <Text fontSize={1} color="positive">Success: {success}</Text>
                <Text fontSize={1} color="negative">Errors: {errors}</Text>
            </Inline>
        </Box>
    );
};

export default Progress;

Progress.defaultProps = {
    errors: 0,
    success: 0,
    total: 0
};