import { writeToString } from '@fast-csv/format';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Box from 'style/layout/Box';
import Heading from 'style/typography/Heading';
import Text from 'style/typography/Text';

import { IResultProps } from './Execute/Execute';

interface IResultComponentProps {
    fileName: string;
    response: IResultProps[];
}

const StyledA = styled.a`
    color: white;
    background-color: #1772c1;
    padding: 20px 200px;
    text-decoration: none;
`;


const Results: React.FC<IResultComponentProps> = (props) => {
    const { fileName, response } = props;
    const [file, setFile] = useState<string | null>(null);

    useEffect(() => {
        const build = async () => {
            const csvText = await toCSV(response);

            if (file !== csvText) {
                setFile(csvText);
            }
        };

        build();
    });

    const today = moment().format('DMMMYYYY');
    const errors = response ? response.filter(rr => rr.status === 'error') : [];
    let blob;
    let blobURL;

    if (file) {
        blob = new Blob([file], { type: 'text/csv' });
        blobURL = URL.createObjectURL(blob);
    }

    const outputFileName = fileName.replace('.csv', ` with Blue Flag data ${today}.csv`);

    return (
        <Box borderTop={1} pt={2}>
            <Heading fontSize={3} fontWeight={3}>Step 5 - Download Output</Heading>

            {
                errors && errors.length > 0 && (
                    <Box>
                        <Text fontSize={0} color="negative">Errors:</Text>
                        {errors.map((rr, ii) => <Text key={ii} fontSize={0} color="negative">{JSON.stringify(rr.variables)} ({rr.error?.message})</Text>)}
                    </Box>
                )
            }

            {
                !file && (
                    <Box mt={3}>
                        <Text fontSize={1} color="primary">Preparing CSV for download...</Text>
                    </Box>
                )
            }

            {
                file && blobURL && (
                    <Box my={5} width="100%">
                        <StyledA href={blobURL} download={outputFileName}>
                            Download CSV
                        </StyledA>
                    </Box>
                )
            }
        </Box>
    );
};

export default Results;

const toCSV = async (response: IResultProps[]): Promise<string> => {
    const fileData = response.map(rr => {
        const result = (rr.result && rr.result[0]) || {};

        const toColumns = (obj: { [key: string]: any }, prefix: string): {} => {
            let csvRow = {};

            if (!obj) {
                return {};
            }

            Object.keys(obj)
                .forEach((col: string) => {
                    const val = obj[col];

                    if (typeof val === 'object') {
                        const nestedObj = toColumns(val, `${prefix}.${col}`);
                        csvRow = {
                            ...csvRow,
                            ...nestedObj
                        };
                    }
                    else {
                        csvRow = {
                            ...csvRow,
                            [`${prefix}.${col}`]: val
                        };
                    }
                });

            return csvRow;
        };

        const flattenApiColumns = result ? toColumns(result, 'blueflag') : {};

        return { ...rr.row, ...flattenApiColumns };
    });

    // @intent Make sure that all rows contain every column or else they will be missing in the csv
    const allColumns = fileData.map(rr => Object.keys(rr || {})).sort((a, b) => b.length - a.length)[0];

    const fileDataWithAllColumns = fileData.map((rr: { [key: string]: any }) => {
        return allColumns
            .map((cc: string) => ({ [cc]: rr[cc] }))
            .reduce((state, val) => ({ ...state, ...val }), {});
    });

    const csvText = await writeToString(fileDataWithAllColumns, { headers: true, rowDelimiter: '\n' });

    return csvText;
};