import React, { useEffect, useState } from 'react';
import { from } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';
import { reduce, scan } from 'rxjs/operators';

import Box from 'style/layout/Box';
import Heading from 'style/typography/Heading';

import Progress from './Progress';

export interface IResultProps {
    error?: Error;
    result?: { [key: string]: any }[];
    row: { [key: string]: any };
    status: 'success' | 'error';
    variables: { plate: string, state: string } | { vin: string };
}

interface IExecute {
    onComplete: (data: IResultProps[]) => void;
    columns: { plateColumn: string, stateColumn: string } | { vinColumn: string };
    rows: {}[];
    request: { query: string, token: string };
}

const CONCURRENT_REQUESTS = 10;

interface IProgress {
    errors: number;
    success: number;
}

const __CACHE__: { [key: string]: Promise<any> } = {};

const Execute: React.FC<IExecute> = (props) => {
    const { rows, columns, request, onComplete } = props;

    const [success, setSuccess] = useState(0);
    const [errors, setErrors] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            // Fetch data
            const api$ = from(rows)
                .pipe(
                    mergeMap(rr => apiFetch(rr, columns, request), CONCURRENT_REQUESTS)
                );

            // Update progress bar
            api$
                .pipe(
                    scan((state: IProgress, val: { status: string }): IProgress => {
                        const { status } = val;

                        if (status === 'success') {
                            return { ...state, success: state.success + 1 };
                        }

                        return { ...state, errors: state.errors + 1 };
                    }, { success: 0, errors: 0 }),
                    debounceTime(10)
                )
                .subscribe(
                    stats => {
                        setSuccess(stats.success);
                        setErrors(stats.errors);
                    }
                );

            // Complete
            const results: IResultProps[] = await new Promise((resolve, reject) => {
                api$
                    .pipe(
                        reduce((
                            state: IResultProps[],
                            val: IResultProps
                        ): IResultProps[] => {
                            return [...state, val];
                        }, [])
                    )
                    .subscribe(
                        (dd: IResultProps[]) => {
                            if (dd) {
                                resolve(dd);
                            }
                        },
                        (err: Error) => reject(err)
                    );
            });

            onComplete(results);
        };

        // @intent I wrapped it in a function so that I can use async/await
        fetchData();
    }, [columns, onComplete, request, rows]);

    return (
        <Box borderTop={1} pt={2}>
            <Heading fontSize={3} fontWeight={3}>Step 4 - Requesting data from NEVDIS</Heading>

            <Progress success={success} errors={errors} total={rows.length} />
        </Box>
    );
};

export default Execute;

const apiFetch = async (
    row: { [key: string]: any },
    columns: { [key: string]: string },
    request: { query: string, token: string }
): Promise<IResultProps> => {
    let variables: { plate: string, state: string } | { vin: string } = { vin: '' };
    let cacheKey = '';

    if (columns.plateColumn && columns.stateColumn) {
        const plate: string = row[columns.plateColumn].trim();
        const state: string = row[columns.stateColumn].trim().toUpperCase();

        variables = { plate, state };
        cacheKey = `${plate}::${state}`;
    }
    else if (columns.vinColumn) {
        const vin: string = row[columns.vinColumn];
        variables = { vin };
        cacheKey = `${vin}`;
    }

    try {
        const cacheHit = __CACHE__[cacheKey];

        let resp;

        if (cacheHit) {
            resp = cacheHit;
        }
        else {
            resp = apiRequest(request, variables);

            // @intent Bypass local cache because we are using server cache
            __CACHE__[cacheKey] = resp;

            // console.log('Fetching ', cacheKey);
        }

        const result: { [key: string]: any }[] = await resp;

        return { result, row, status: 'success', variables };
    }
    catch (err) {
        return { error: err, row, status: 'error', variables };
    }
};

const apiRequest = async (req: { query: string, token: string }, variables: { plate: string, state: string } | { vin: string }): Promise<{ [key: string]: any }[]> => {
    // if (plate === '1TKU237') {
    //     throw new Error('::1TKU237 on purpose!');
    // }

    // return [{
    //     colA: 1234
    // }];

    // tslint:disable: object-literal-sort-keys
    const response = await fetch('https://ubuxgyols2.execute-api.ap-southeast-2.amazonaws.com/prod/', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${req.token}`
        },
        body: JSON.stringify({
            query: req.query,
            variables
        })
    });
    // tslint:enable: object-literal-sort-keys

    const result = await response.json();

    if (result && result.errors && result.errors.length > 0) {
        console.log('ERROR:', variables, result.errors[0]);
        throw new Error(result.errors[0]);
    }

    const key = Object.keys(result.data)[0];

    return result.data[key];
};