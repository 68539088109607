import { parseString } from '@fast-csv/parse';
import React from 'react';

import Box from 'style/layout/Box';
import Heading from 'style/typography/Heading';


interface IUploadCSV {
    onChange: (fileName: string, rows: {}[]) => void;
}

const UploadCSV: React.FC<IUploadCSV> = (props) => {
    const { onChange } = props;

    const onFileSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;

        if (!files || files.length === 0) {
            return null;
        }

        const csvText = await readFile(files[0]);
        if (!csvText) {
            throw new Error('Cannot read the file. It looks blank to me.');
        }

        const rows = await parseCSV(csvText?.toString());

        if (!rows || rows.length === 0) {
            throw new Error('Could not find any rows in your file');
        }

        onChange(files[0].name, rows);
    };

    return (
        <Box>
            <Heading fontSize={3} fontWeight={3}>Upload CSV</Heading>
            <input type="file" accept=".csv" onChange={onFileSelected} />
        </Box>
    );
};

export default UploadCSV;

const readFile = async (file: File): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
        const fr = new FileReader();

        fr.onloadend = () => resolve(fr.result);
        fr.onerror = () => {
            fr.abort();
            reject(new Error('Could not read the csv'));
        };
        fr.readAsText(file);
    });
};

const parseCSV = async (csv: string): Promise<{}[]> => {
    return new Promise((resolve, reject) => {
        const rows: {}[] = [];

        parseString(csv, { headers: true })
            .on('error', error => reject(error))
            .on('data', row => rows.push(row))
            .on('end', () => resolve(rows));
    });
};
