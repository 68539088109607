import styled from 'styled-components/macro';
import { border, color, flexbox, layout, position, space, typography, width } from 'styled-system';
import { BorderProps, ColorProps, FlexboxProps, LayoutProps, PositionProps, SpaceProps, TypographyProps, WidthProps } from 'styled-system';

export interface IBoxProps extends BorderProps, ColorProps, LayoutProps, SpaceProps, WidthProps, TypographyProps, FlexboxProps, PositionProps { }

const Box = styled.div<IBoxProps>`
    ${border}
    ${color}
    ${flexbox}
    ${layout}
    ${position}
    ${space}
    ${typography}
    ${width}
`;

export default Box;
