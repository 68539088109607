// tslint:disable: object-literal-sort-keys

/**
 * Semantic Color
 * Our choice of colors are based on this document https://spectrum.adobe.com/page/color/#Semantic-colors
 *
 * TODO: To generate custom themes, consider using something like https://github.com/adobe/leonardo
 */

export const baseColors: { [color: string]: string } = {
    white: '#ffffff',
    black: '#000000',

    'gray-50': '#fefefe',
    'gray-75': '#fafafa',
    'gray-100': '#f5f5f5',
    'gray-200': '#eaeaea',
    'gray-300': '#e1e1e1',
    'gray-400': '#cacaca',
    'gray-500': '#b3b3b3',
    'gray-600': '#8e8e8e',
    'gray-700': '#6e6e6e',
    'gray-800': '#4b4b4b',
    'gray-900': '#2c2c2c',

    'blue-400': '#2680eb',
    'blue-500': '#1473e6',
    'blue-600': '#0d66d0',
    'blue-700': '#095aba',

    'green-400': '#2d9d78',
    'green-500': '#268e6c',
    'green-600': '#12805c',
    'green-700': '#107154',

    'orange-400': '#e68619',
    'orange-500': '#da7b11',
    'orange-600': '#cb6f10',
    'orange-700': '#bd640d',

    'pink-100': '#f5e1ec',
    'pink-200': '#f1c7de',
    'pink-300': '#f09ecd',
    'pink-400': '#f07dc2',
    'pink-500': '#f05dbb',
    'pink-600': '#dc3ba7',
    'pink-700': '#bb3e8f',
    'pink-800': '#a33c7d',
    'pink-900': '#8f3a6e',

    'red-400': '#e34850',
    'red-500': '#d7373f',
    'red-600': '#c9252d',
    'red-700': '#bb121a'
};

export const color = (name: string): string => {
    if (!baseColors[name]) {
        console.log('Missing color', name);
    }

    return baseColors[name] || 'aqua';
};
