import React from 'react';

import Text, { ITextProps } from 'style/typography/Text';

const Heading: React.FC<ITextProps> = (props) => (
    <Text {...props} />
);

export default Heading;

Heading.defaultProps = {
    color: 'text-dark',
    fontFamily: 'heading',
    fontSize: 6,
    fontWeight: 6,
    lineHeight: 'heading'
};
