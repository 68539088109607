import styled from 'styled-components/macro';
import { color, typography } from 'styled-system';
import { ColorProps, TypographyProps } from 'styled-system';

export interface ITextProps extends ColorProps, TypographyProps { }

const Text = styled.div.attrs((props: any) => props) <ITextProps>`
    ${color}
    ${typography}
`;

export default Text;

Text.defaultProps = {
    color: 'text',
    fontFamily: 'text',
    fontSize: 3,
    lineHeight: 'text'
};
